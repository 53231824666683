import React, { FC } from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { Book, CompleteStatusEnum } from '~/api/book/bookApiTypes';
import { ListenIcon } from '~/atomic/atom/icon/ListenIcon';
import { AuthorLink, GenreLink } from '~/atomic/atom/links/dynamicLinks';
import { BookStatus, SCStatus } from '~/atomic/molecula/BookStatus';
import { SCTextSmall, TextSmallStyle } from '~/atomic/Typography';
import { BookCardStatus } from '~/feature/book/BookCard/BookCardStatus';
import { lessThan } from '~/lib/mediaQuery';
import { numberShorter } from '~/lib/numberShortest';

import {
  SCBookCardAuthor, SCBookInfo, SCBookTitle,
} from './styled';

interface DeletedBookCardOverlayProps {
  isShowOverlay: boolean;
  selectedType: MyBookStatusEnum;
  setIsShowOverlay: (isShowOverlay: boolean) => void;
  book: Book;
}

const lorem = 'Краткое описание книги';

export const DeletedBookCardOverlay: FC<DeletedBookCardOverlayProps> = ({
  isShowOverlay,
  setIsShowOverlay,
  selectedType,
  book,
}) => {
  return (
    <SCBookCardOverlay
      data-is-show-overlay={String(isShowOverlay)}
      onMouseLeave={() => { setIsShowOverlay(false); }}
    >
      <SCBookActions>
        <BookCardStatus
          book={book}
          selectedType={selectedType}
          setIsShowOverlay={setIsShowOverlay}
        />
        <SCReadBook />
        <SCListenBookWrapper>
          <SCListenIcon />
        </SCListenBookWrapper>
      </SCBookActions>
      <SCScrollContainer>
        <SCBookInfo>
          <SCBookTitle as="span" title={book?.name ?? 'Название книги'}>
            {book?.name ?? 'Название книги' }
          </SCBookTitle>
          <AuthorLink username={book?.authors?.[0]?.username ?? ''}>
            <SCBookCardAuthor title={book?.authors?.[0]?.name ?? 'Имя автора'}>
              {book?.authors?.[0]?.name ?? 'Имя автора' }
            </SCBookCardAuthor>
          </AuthorLink>
        </SCBookInfo>
        <SCBookStatistic>
          {book.statusComplete === CompleteStatusEnum.Complete
            ? (
              <BookStatus
                type={CompleteStatusEnum.Complete}
                text="Полностью"
              />
            ) : (
              <BookStatus
                type={book.statusComplete}
              />
            )}
        </SCBookStatistic>
        <SCTextSmall>
          { `${numberShorter(book?.symbols)} зн.` }
        </SCTextSmall>
        <SCGenreList>
          {book?.genres?.map((genre) => (
            <SCGenreItem
              key={genre.slug}
            >
              <GenreLink
                slug={genre.slug}
              >
                <SCGenreLink>
                  {genre.name}
                </SCGenreLink>
              </GenreLink>
            </SCGenreItem>
          ))}
        </SCGenreList>
        <SCBookDescription>{book?.annotation ?? lorem}</SCBookDescription>
      </SCScrollContainer>
    </SCBookCardOverlay>
  );
};

const SCBookStatistic = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
  flex-wrap: wrap;
  
  ${SCTextSmall} {
    margin-top: 4px;
  }
  
  ${SCStatus} {
    font-size: 10px;
    padding-left: 25px;
  }
`;

const SCListenBookWrapper = styled.div`
  background-color: var(--black-color);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-is-available-listen="false"] {
    cursor: default;
    filter: contrast(0.4) brightness(1.1);
    pointer-events: none;
  }
`;

const SCReadBook = styled.div`
  background-color: var(--yellow-dark-color);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  
  &:after {
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    position: absolute;
    background-color: var(--black-color);
    mask-image: url('/icon/components/BookCard/my-book.svg');
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: auto;
  }
`;

const SCBookActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  margin-top: 4px;
  justify-content: space-evenly;
  align-items: center;
`;

const SCBookDescription = styled.div`
  color: var(--gray-color);
  font-family: var(--second-font);
  display: block;

  font-size: 13px;
  line-height: 18px;
  max-width: 100%;
  word-break: break-word;
`;

const SCScrollContainer = styled.div`
  overflow-y: scroll;
`;

const SCBookCardOverlay = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  display: flex;
  flex-direction: column;
  width: 110%;
  height: 110%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  border: 1px solid var(--bg-color);
  border-radius: 4px;
  padding: 8px 8px;
  background-color: var(--white-color);
  box-shadow: 0 3px 8px rgba(53, 63, 72, 0.25);
  transition: 0.3s;
  
  &[data-is-show-overlay="true"] {
    pointer-events: auto;
    opacity: 1;
  }
  
  ${lessThan('xs')} {
    display: none;
  }
`;

const SCGenreList = styled.ul`
  margin-bottom: 6px;
  margin-top: 4px;
  line-height: 18px;
`;

const SCGenreLink = styled.a`
  ${TextSmallStyle};
  line-height: 15px;
  font-weight: 500;
  color: var(--black-color);
  margin-right: 2px;
`;

const SCGenreItem = styled.li`
  padding: 0;
  list-style: none;
  display: inline-flex;

  &:not(:last-child) {
    ${SCGenreLink} {
      &:after {
        content: ',';
        left: 0;
      }
    }
  }
`;

const SCListenIcon = styled(ListenIcon)`
  width: 20px;
  height: 20px;
  fill: var(--gray3-color);
`;
