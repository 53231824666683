import React, { forwardRef } from 'react';

import { DeletedBookCard } from '~/feature/book/BookCard/DeletedBookCard';

import { DefaultBookCard, DefaultBookCardProps } from './DefaultBookCard';

export const BookCard = forwardRef<HTMLDivElement, DefaultBookCardProps>(({
  book,
  onChangeMyBookStatus,
  isMyBooks,
  isShowPartnerProgram = false,
  cover,
  isFb2,
  goToBook,
  ...props
}, ref) => {
  if (book?.deleted && !book?.myBook?.buy) {
    return <DeletedBookCard ref={ref} book={book} />;
  }

  return (
    <DefaultBookCard
      ref={ref}
      book={book}
      onChangeMyBookStatus={onChangeMyBookStatus}
      isMyBooks={isMyBooks}
      cover={cover}
      isFb2={isFb2}
      isShowPartnerProgram={isShowPartnerProgram}
      goToBook={goToBook}
      {...props}
    />
  );
});
