import React, { FC } from 'react';

import { AuthorLink, BookLink } from '~/atomic/atom/links/dynamicLinks';
import { DefaultBookCardProps } from '~/feature/book/BookCard/DefaultBookCard';
import {
  SCBookCardAuthor, SCBookInfo, SCBookTitle,
} from '~/feature/book/BookCard/styled';

export const BookCardInfo: FC<Pick<DefaultBookCardProps, 'book' | 'goToBook' | 'bookLinkQueries'>> = ({
  book, goToBook, bookLinkQueries,
}) => {
  return (
    <SCBookInfo>
      <BookLink query={bookLinkQueries} slug={book?.slug}>
        <SCBookTitle onClick={goToBook} title={book?.name ?? 'Название книги'}>
          {book?.name ?? 'Название книги' }
        </SCBookTitle>
      </BookLink>
      <AuthorLink username={book?.authors?.[0]?.username ?? ''}>
        <SCBookCardAuthor title={book?.authors?.[0]?.name ?? 'Имя автора'}>
          {book?.authors?.[0]?.name ?? 'Имя автора' }
        </SCBookCardAuthor>
      </AuthorLink>
    </SCBookInfo>
  );
};
