import { ParsedUrlQueryInput } from 'querystring';
import React, {
  forwardRef, ReactNode, useEffect, useState,
} from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { Book } from '~/api/book/bookApiTypes';
import { BookLink } from '~/atomic/atom/links/dynamicLinks';
import { BookCardBadgers } from '~/feature/book/BookCard/BookCardBadgers';
import { BookCardCover } from '~/feature/book/BookCard/BookCardCover';
import { BookCardHideBlock } from '~/feature/book/BookCard/BookCardHideBlock';
import { BookCardInfo } from '~/feature/book/BookCard/BookCardInfo';
import { BookCardOverlay } from '~/feature/book/BookCard/BookCardOverlay';
import { BookCardReadStatusBadger } from '~/feature/book/BookCard/BookCardReadStatusBadger';
import { BookCardRecommendation } from '~/feature/book/BookCard/BookCardRecommendation';
import { lessThan } from '~/lib/mediaQuery';

import { BookCardExclusive } from './BookCardExclusive';
import { NewSymbols } from './NewSymbols';

export interface DefaultBookCardProps extends React.HTMLAttributes<HTMLDivElement> {
  book: Book;
  onChangeMyBookStatus?: () => void;
  isMyBooks?: boolean;
  cover?: ReactNode;
  isFb2?: boolean;
  isShowPartnerProgram?: boolean;
  goToBook?: () => void;
  bookLinkQueries?: ParsedUrlQueryInput;
}

export const DefaultBookCard = forwardRef<HTMLDivElement, DefaultBookCardProps>(({
  book,
  onChangeMyBookStatus,
  isShowPartnerProgram = false,
  isMyBooks,
  cover,
  isFb2,
  goToBook = () => {},
  bookLinkQueries = {},
}, ref) => {
  const [isHideRecommendation, changeIsHideRecommendation] = useState(
    book?.recommendation?.feedbackRating === false,
  );
  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const [isHideBook, setIsHideBook] = useState(false);

  const [
    selectedType,
    setSelectedType,
  ] = useState<MyBookStatusEnum>(book?.myBook?.readStatus);

  useEffect(() => {
    setSelectedType(book?.myBook?.readStatus);
  }, [book]);

  const setIsShowOverlayHandler = (isShow: boolean) => {
    if (!isHideBook) {
      setIsShowOverlay(isShow);
    }
  };

  const onChangeMyBookStatusHandler = () => {
    if (onChangeMyBookStatus) onChangeMyBookStatus();
  };

  return (
    <SCBookCardWrapper ref={ref} data-in-hide-book={isHideRecommendation}>
      <SCBookCard
        onMouseLeave={() => { setIsShowOverlayHandler(false); }}
      >
        <BookCardReadStatusBadger status={selectedType} />
        <BookLink
          slug={book.slug}
          query={bookLinkQueries}
        >
          <SCBookCoverContainer
            onClick={goToBook}
            onMouseEnter={() => {
              setIsShowOverlayHandler(true);
            }}
          >
            <BookCardExclusive isExclusive={book.exclusive} />
            <BookCardBadgers
              bought={book?.myBook?.buy}
              discount={book?.discount}
              book={book}
              isShowPartnerProgram={isShowPartnerProgram}
            />
            {cover ?? (
              <div
                itemScope
                itemType="https://schema.org/ImageObject"
              >
                <BookCardCover
                  coverImages={book?.coverImages}
                  book={{
                    title: book?.name,
                    author: book?.authors?.[0]?.name,
                  }}
                  itemProp="thumbnail"
                />
              </div>
            )}
          </SCBookCoverContainer>
        </BookLink>
        <BookCardInfo bookLinkQueries={bookLinkQueries} goToBook={goToBook} book={book} />
        <BookCardOverlay
          book={book}
          isShowOverlay={isShowOverlay}
          onChangeMyBookStatus={onChangeMyBookStatusHandler}
          setSelectedType={setSelectedType}
          selectedType={selectedType}
          setIsHideBook={setIsHideBook}
          setIsShowOverlay={setIsShowOverlay}
          isFb2={isFb2}
          goToBook={goToBook}
          bookLinkQueries={bookLinkQueries}
        />
        {isMyBooks && (
          <NewSymbols currentSymbols={book?.symbols} newSymbols={book?.myBook?.symbols} />
        )}
      </SCBookCard>
      <BookCardRecommendation
        book={book}
        isHideRecommendation={isHideRecommendation}
        changeIsHideRecommendation={changeIsHideRecommendation}
      />
      <BookCardHideBlock isHideBook={isHideBook} setIsHideBook={setIsHideBook} book={book} />
    </SCBookCardWrapper>
  );
});

const SCBookCardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &[data-in-hide-book="true"] {
    opacity: 0.4;
  }
`;

const SCBookCoverContainer = styled.a`
  width: 100%;
  position: relative;
  margin-bottom: 12px;

  ${lessThan('xxs')} {
    margin-bottom: 3px;
  }
`;

const SCBookCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid var(--bg-color);
  box-shadow: 0 1px 1px rgba(141, 147, 162, 0.25);
  border-radius: 4px;
  padding: 6px;

  ${lessThan('xs')} {
    padding: 6px;
  }
`;
