import React, { FC } from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { CompleteStatusEnum } from '~/api/book/bookApiTypes';
import {
  AuthorLink, BookLink, GenreLink,
} from '~/atomic/atom/links/dynamicLinks';
import { BookStatus, SCStatus } from '~/atomic/molecula/BookStatus';
import { SCTextSmall, TextSmallStyle } from '~/atomic/Typography';
import { BookCardOverlayListenButton } from '~/feature/book/BookCard/BookCardOverlayListenButton';
import { BookCardOverlayReadButton } from '~/feature/book/BookCard/BookCardOverlayReadButton';
import { BookCardStatus } from '~/feature/book/BookCard/BookCardStatus';
import { DefaultBookCardProps } from '~/feature/book/BookCard/DefaultBookCard';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { lessThan } from '~/lib/mediaQuery';
import { numberShorter } from '~/lib/numberShortest';
import { useAppSelector } from '~/store';

import { BookCardOverlayPrice } from './BookCardOverlayPrice';
import {
  SCBookCardAuthor, SCBookInfo, SCBookTitle,
} from './styled';

export interface BookCardOverlayProps extends DefaultBookCardProps {
  isShowOverlay: boolean;
  setSelectedType: (params: MyBookStatusEnum) => void;
  selectedType: MyBookStatusEnum;
  setIsHideBook?: (isShowCard: boolean) => void;
  setIsShowOverlay: (isShowOverlay: boolean) => void;
}

const lorem = 'Краткое описание книги';

export const BookCardOverlay: FC<BookCardOverlayProps> = ({
  isShowOverlay,
  setSelectedType,
  setIsShowOverlay,
  onChangeMyBookStatus,
  selectedType,
  book,
  setIsHideBook,
  goToBook,
  bookLinkQueries,
}) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isHaveDiscount = (book.price !== book.discount?.price ?? '') && !book?.myBook?.buy;

  if (isMobileSize) {
    return null;
  }

  return (
    <SCBookCardOverlay
      data-is-show-overlay={String(isShowOverlay)}
      onMouseLeave={() => { setIsShowOverlay(false); }}
    >
      <SCBookActions>
        <BookCardStatus
          book={book}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setIsHideBook={setIsHideBook}
          onChangeMyBookStatus={onChangeMyBookStatus}
          setIsShowOverlay={setIsShowOverlay}
        />
        <BookCardOverlayReadButton query={bookLinkQueries} book={book} />
        <BookCardOverlayListenButton book={book} />
      </SCBookActions>
      <SCScrollContainer>
        <SCBookInfo>
          <BookLink query={bookLinkQueries} slug={book?.slug}>
            <SCBookTitle onClick={goToBook} title={book?.name ?? 'Название книги'}>
              {book?.name ?? 'Название книги' }
            </SCBookTitle>
          </BookLink>
          <AuthorLink username={book?.authors?.[0]?.username ?? ''}>
            <SCBookCardAuthor title={book?.authors?.[0]?.name ?? 'Имя автора'}>
              {book?.authors?.[0]?.name ?? 'Имя автора' }
            </SCBookCardAuthor>
          </AuthorLink>
        </SCBookInfo>
        <SCBookStatistic>
          {book.statusComplete === CompleteStatusEnum.Complete
            ? (
              <BookStatus
                type={CompleteStatusEnum.Complete}
                text="Полностью"
              />
            ) : (
              <BookStatus
                type={book.statusComplete}
              />
            )}
          {book?.ebook?.available ? (
            <SCTextSmall>
              {`${numberShorter(book?.symbols)}`}
            </SCTextSmall>
          ) : null}
        </SCBookStatistic>
        <SCGenreList>
          {book?.genres?.map((genre) => (
            <SCGenreItem
              key={genre.slug}
            >
              <GenreLink
                slug={genre.slug}
              >
                <SCGenreLink>
                  {genre.name}
                </SCGenreLink>
              </GenreLink>
            </SCGenreItem>
          ))}
        </SCGenreList>
        <SCBookDescription>{book?.annotation ?? lorem}</SCBookDescription>
      </SCScrollContainer>
      <SCBookPriceWrapper
        data-is-have-discount={isHaveDiscount}
      >
        <BookCardOverlayPrice
          book={book}
          bookLinkQueries={bookLinkQueries}
        />
      </SCBookPriceWrapper>
    </SCBookCardOverlay>
  );
};

const SCBookStatistic = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  flex-wrap: wrap;
  
  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${SCStatus} {
    font-size: 10px;
    padding-left: 25px;
  }
`;

const SCBookActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  margin-top: 4px;
  justify-content: space-evenly;
  align-items: center;
`;

const SCBookDescription = styled.div`
  color: var(--gray-color);
  font-family: var(--second-font);
  display: block;

  font-size: 13px;
  line-height: 18px;
  max-width: 100%;
  word-break: break-word;
`;

const SCScrollContainer = styled.div`
  overflow-y: scroll;
`;

const SCBookCardOverlay = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  display: flex;
  flex-direction: column;
  width: 110%;
  height: 110%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  border: 1px solid var(--bg-color);
  border-radius: 4px;
  padding: 8px 8px;
  background-color: var(--white-color);
  box-shadow: 0 3px 8px rgba(53, 63, 72, 0.25);
  transition: 0.3s;

  &[data-is-show-overlay="true"] {
    pointer-events: auto;
    opacity: 1;
  }

  ${lessThan('xs')} {
    display: none;
  }
`;

const SCBookPriceWrapper = styled.div`
  background: #fff;
  padding: 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-is-have-discount="true"] {
    margin-right: 45px;
  }
`;

const SCGenreList = styled.ul`
  margin-bottom: 6px;
  margin-top: 4px;
  line-height: 18px;
`;

const SCGenreLink = styled.a`
  ${TextSmallStyle};
  line-height: 15px;
  font-weight: 500;
  color: var(--black-color);
  margin-right: 2px;
`;

const SCGenreItem = styled.li`
  padding: 0;
  list-style: none;
  display: inline-flex;

  &:not(:last-child) {
    ${SCGenreLink} {
      &:after {
        content: ',';
        left: 0;
      }
    }
  }
`;
