import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';

import { Book } from '~/api/book/bookApiTypes';
import { AuthorLink } from '~/atomic/atom/links/dynamicLinks';
import { BookCardCover } from '~/feature/book/BookCard/BookCardCover';
import { BookCardReadStatusBadger } from '~/feature/book/BookCard/BookCardReadStatusBadger';
import { DeletedBookCardOverlay } from '~/feature/book/BookCard/DeletedBookCardOverlay';
import {
  SCBookCardAuthor, SCBookInfo, SCBookTitle,
} from '~/feature/book/BookCard/styled';
import { lessThan } from '~/lib/mediaQuery';

interface DeletedBookCardProps {
  book: Book;
}

export const DeletedBookCard = forwardRef<HTMLDivElement, DeletedBookCardProps>(({ book }, ref) => {
  const [isShowOverlay, setIsShowOverlay] = useState(false);

  const setIsShowOverlayHandler = (isShow: boolean) => {
    setIsShowOverlay(isShow);
  };

  return (
    <SCBookCardWrapper ref={ref}>
      <SCBookCard
        onMouseLeave={() => { setIsShowOverlayHandler(false); }}
      >
        <BookCardReadStatusBadger status={book?.myBook?.readStatus} />
        <SCBookCoverContainer
          onMouseEnter={() => {
            setIsShowOverlayHandler(true);
          }}
        >
          <SCBookCardExclusive>
            <SCText>
              Удалена
            </SCText>
          </SCBookCardExclusive>
          <div
            itemScope
            itemType="https://schema.org/ImageObject"
          >
            <BookCardCover
              coverImages={book?.coverImages}
              book={{
                title: book?.name,
                author: book?.authors?.[0]?.name,
              }}
              itemProp="thumbnail"
            />
          </div>
        </SCBookCoverContainer>
        <SCBookInfo>
          <SCBookTitle as="span" title={book?.name ?? 'Название книги'}>
            {book?.name ?? 'Название книги' }
          </SCBookTitle>
          <AuthorLink username={book?.authors?.[0]?.username ?? ''}>
            <SCBookCardAuthor title={book?.authors?.[0]?.name ?? 'Имя автора'}>
              {book?.authors?.[0]?.name ?? 'Имя автора' }
            </SCBookCardAuthor>
          </AuthorLink>
        </SCBookInfo>
        <DeletedBookCardOverlay
          book={book}
          isShowOverlay={isShowOverlay}
          selectedType={book?.myBook?.readStatus}
          setIsShowOverlay={setIsShowOverlay}
        />
      </SCBookCard>
    </SCBookCardWrapper>
  );
});

const SCBookCardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0.5;

  &[data-in-hide-book="true"] {
    opacity: 0.4;
  }
`;

const SCBookCoverContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  
  ${lessThan('xxs')} {
    margin-bottom: 3px;
  }
`;

const SCBookCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid var(--bg-color);
  box-shadow: 0 1px 1px rgba(141, 147, 162, 0.25);
  border-radius: 4px;
  padding: 8px 12px 10px;

  ${lessThan('xs')} {
    padding: 4px;
  }
`;

const SCText = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: var(--white-color);
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: var(--error-color);
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 19px;
  right: -21px;
  z-index: 3;

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    top: 14px;
    z-index: -1;
    background-color: var(--error-color);
    width: 12px;
    height: 6px;
    transform: rotate(-45deg);
  }
  
  &:after {
    content: "";
    position: absolute;
    right: -2px;
    bottom: -1px;
    z-index: -1;
    background-color: var(--error-color);
    width: 12px;
    height: 6px;
    transform: rotate(45deg);
  }
`;

const SCBookCardExclusive = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 3;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  
  &[data-size="large"] {
    width: 100px;
    height: 100px;

    ${SCText} {
      top: 30px;
      right: -37px;
      width: 150px;
      font-size: 14px;
      line-height: 23px;

      &:before {
        left: 3px;
        top: 19px;
      }
      
      &:after {
        right: 7px;
        bottom: -3px;
      }
    }
  }
`;
